import { useAppMode } from './use-app-mode';
import type { IAccountContext } from '~/types/account-context';

const accountContext = ref<IAccountContext | undefined>(undefined);

export default function useContextSlug () {
    async function resolveAccountContext () {
        const { isAccountDirect, accountContextSlug } = useAppMode();

        // if we are in ACCOUNT_DIRECT mode and we don't have the account context yet, fetch it
        if (isAccountDirect && accountContextSlug && !accountContext.value) {
            accountContext.value = await getAccountContextBySlug(accountContextSlug);
        }

        return accountContext;
    }

    return {
        accountContext,
        resolveAccountContext,
    };
}
