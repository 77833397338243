import type { ILocationDetails } from '~/types/bookings';
import type { ILocation } from '~/types/services';
import type { IRegisterUser, IUserAccountInfo, TMissingData, TSignInMethod } from '~/types/user';

export async function sync (): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/user/sync', {
        method: 'PATCH',
    }, false);

    return response.success;
}

export async function hasMissingData (filterCheckoutOnly: boolean): Promise<TMissingData> {
    const loginOnlyMissingData: TMissingData = ['location', 'paymentMethod'];
    /*
    const response = await Promise.resolve({
        data: {
            missingData: [
                'firstName',
                'lastName',
                'phone',
                'location',
                'paymentMethod',
            ] as TMissingData
        }
    });
*/
    const response = await useAPI<{ success: boolean, data: { missingData: TMissingData } }>('/v1/user/missing-data', {
        method: 'GET',
    }, false);

    const missingData = response.data?.missingData || [];

    return filterCheckoutOnly ? missingData.filter(field => !loginOnlyMissingData.includes(field)) : missingData;
}

export async function updateMissingData (firstName: string, lastName: string, phone: string, location: ILocationDetails | null, passwordIsSet?: boolean): Promise<Object> {
    const body: {
        firstName?: string;
        lastName?: string;
        phone?: string;
        passwordIsSet?: boolean;
        location?: ILocationDetails;
    } = {};

    if (firstName) {
        body.firstName = firstName;
    }
    if (lastName) {
        body.lastName = lastName;
    }
    if (phone) {
        body.phone = phone;
    }
    if (passwordIsSet !== undefined) {
        body.passwordIsSet = passwordIsSet;
    }

    if (location) {
        body.location = {
            address: location.address,
            city: location.city,
            state: location.state,
            zipcode: location.zipcode || '',
        };
    }

    const response = await useAPI<{ success: boolean, data: Record<string, string> }>('/v1/user/missing-data', {
        method: 'PATCH',
        body: JSON.stringify(body),
    });

    return response.data;
}

export async function login (email: string, password: string): Promise<boolean> {
    const appConfig = useAppConfig();
    let response: { success: boolean };

    if (appConfig.useMockData) {
        response = await $fetch('/mock/user/paginate.json');
    } else {
        response = await useAPI<{ success: boolean }>('/v1/user/login', {
            method: 'POST',
            body: JSON.stringify({
                email,
            }),
        });
    }

    return response.success;
}

export async function register (user: IRegisterUser): Promise<boolean> {
    const appConfig = useAppConfig();
    let response: { success: boolean };

    if (appConfig.useMockData) {
        response = await $fetch('/mock/user/paginate.json');
    } else {
        response = await useAPI<{ success: boolean }>('/v1/user/login', {
            method: 'POST',
            body: JSON.stringify(user),
        });
    }

    return response.success;
}

export async function setPassword (password: string): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/user/set-password', {
        method: 'PATCH',
        body: JSON.stringify({
            password,
        }),
    });

    return response.success;
}

export async function sendAuthLink (email: string): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/send-auth-link', {
        method: 'POST',
        body: JSON.stringify({
            email,
        }),
    });

    return response.success;
}

/**
 * Retrieves details about the currently logged in user.
 *
 * @returns {Promise<{ firstName: string, lastName: string }>} - A promise that resolves to the details of a user
 */
export async function getUserInfo (): Promise<{ firstName: string, lastName: string, phoneNumber: string, requiresPasswordSetup?: boolean, signInMethod: TSignInMethod }> {
    const response = await useAPI<{ data: { firstName: string; lastName: string, phoneNumber: string, signInMethod: TSignInMethod } }>('/v1/user/info', {
        method: 'GET',
    });

    return response.data;
}

/**
 * Retrieves details about the currently logged in user.
 *
 * @returns {Promise<{ firstName: string, lastName: string }>} - A promise that resolves to the details of a user
 */
export async function getAccountUserInfo (): Promise<IUserAccountInfo> {
    const response = await useAPI<{ data: IUserAccountInfo }>('/v1/user/settings/personal-info', {
        method: 'GET',
    });

    return formatPersonalInfo(response.data);
}

export async function updateName (firstName: string, lastName: string): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/user/settings/name', {
        method: 'PUT',
        body: JSON.stringify({
            firstName,
            lastName,
        }),
    });

    return response.success;
}

export async function updatePhone (phone: string): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/user/settings/phone', {
        method: 'PUT',
        body: JSON.stringify({
            phone: `+1${(phone || '').replace(/[^\d]/g, '')}`, // turn to format +1XXXXXXXXXX,
        }),
    });

    return response.success;
}

export async function confirmPhoneUpdate (code: string): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/user/settings/phone/confirm', {
        method: 'PUT',
        body: JSON.stringify({
            code,
        }),
    });

    return response.success;
}

export async function updateEmail (email: string): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/user/settings/email', {
        method: 'PUT',
        body: JSON.stringify({
            email,
        }),
    });

    return response.success;
}

export async function confirmEmailUpdate (code: string): Promise<boolean> {
    const response = await useAPI<{ success: boolean }>('/v1/user/settings/email/confirm', {
        method: 'PUT',
        body: JSON.stringify({
            code,
        }),
    });

    return response.success;
}

function formatPersonalInfo (data: IUserAccountInfo): IUserAccountInfo {
    return {
        ...data,
        phone: (data.phone || '').replace(/^\+1/, ''), // remove the +1
    };
}
