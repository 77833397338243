import { defineAsyncComponent } from 'vue'
export default {
  account: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/account.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/default.vue").then(m => m.default || m)),
  details: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/details.vue").then(m => m.default || m)),
  external: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/external.vue").then(m => m.default || m)),
  "home-account-direct": defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/home-account-direct.vue").then(m => m.default || m)),
  home: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/home.vue").then(m => m.default || m)),
  login: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/login.vue").then(m => m.default || m)),
  plain: defineAsyncComponent(() => import("/opt/buildhome/repo/layouts/plain.vue").then(m => m.default || m))
}