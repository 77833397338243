import validate from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/pages/runtime/validate.js";
import account_45context_45slug_45global from "/opt/buildhome/repo/middleware/account-context-slug.global.ts";
import auth_45check_45global from "/opt/buildhome/repo/middleware/auth-check.global.ts";
import layout_45switcher_45global from "/opt/buildhome/repo/middleware/layout-switcher.global.ts";
import trailing_45slash_45global from "/opt/buildhome/repo/middleware/trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  account_45context_45slug_45global,
  auth_45check_45global,
  layout_45switcher_45global,
  trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}