import payload_plugin_wTRwttg37l from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.0_pinia@3.0.1_rollup@4.34.4/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_gX647DCzXt from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7qbu7sy8aO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_JdjOjBO1Sh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_LFVfdy5YGj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JQ8xthbglk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GFSeIlqN2s from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_sLS8LOqfQd from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_92Q4yjYYWm from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.10.0_pinia@3.0.1_rollup@4.34.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_mPtcKotEhW from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.15.4_eslint@8.57.1_rollup@4.34.4_sass@1.84.0_typescript@5.5.3_vite@4.5.9_vue-tsc@2.0.26/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_bMpA0MHQ9f from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.34.4_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_mO1tV1D6XC from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_rollup@4.34.4_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import firebase_Wjz9XrZo01 from "/opt/buildhome/repo/plugins/firebase.ts";
import middleware_global_fxkQLky8iN from "/opt/buildhome/repo/plugins/middleware.global.ts";
import vuedatepicker_oKNl9XPOyX from "/opt/buildhome/repo/plugins/vuedatepicker.ts";
export default [
  payload_plugin_wTRwttg37l,
  revive_payload_client_gX647DCzXt,
  unhead_7qbu7sy8aO,
  router_JdjOjBO1Sh,
  payload_client_LFVfdy5YGj,
  navigation_repaint_client_JQ8xthbglk,
  check_outdated_build_client_GFSeIlqN2s,
  chunk_reload_client_sLS8LOqfQd,
  plugin_vue3_92Q4yjYYWm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mPtcKotEhW,
  switch_locale_path_ssr_bMpA0MHQ9f,
  i18n_mO1tV1D6XC,
  firebase_Wjz9XrZo01,
  middleware_global_fxkQLky8iN,
  vuedatepicker_oKNl9XPOyX
]