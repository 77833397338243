import type { IAccountContext, IAccountContextApiSingleResponse, IAccountContextResponse } from '~/types/account-context';

/**
 * Retrieves a single Account Context by its slug
 *
 * @param {string} slug - The slug of the Account Context to retrieve.
 * @returns {Promise<IAccountContext>} - A promise that resolves to the details of a single area.
 */
export async function getAccountContextBySlug (slug: string): Promise<IAccountContext> {
    const response = await useAPI<IAccountContextApiSingleResponse>(`/v1/account-context/${slug}`, {
        method: 'GET',
    });

    return formatAccountContext(response.data);
}

/**
 * Formats a single Account Context response from the server into a front end object
 * @param {IAccountContextResponse} accountContext A response object received from the API
 * @returns {IAccountContext}
 */
function formatAccountContext (accountContext: IAccountContextResponse): IAccountContext {
    return {
        ...accountContext,
    };
}
