
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93HxQxjzxhjNMeta } from "/opt/buildhome/repo/pages/[...slug].vue?macro=true";
import { default as _4047Xi4OILrTdMeta } from "/opt/buildhome/repo/pages/404.vue?macro=true";
import { default as _91_91filter_93_93R5OzQx1y5ZMeta } from "/opt/buildhome/repo/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexy4DdxYIhQyMeta } from "/opt/buildhome/repo/pages/account/index.vue?macro=true";
import { default as notificationsvs4SYXstdeMeta } from "/opt/buildhome/repo/pages/account/notifications.vue?macro=true";
import { default as paymentscnPfEikkxIMeta } from "/opt/buildhome/repo/pages/account/payments.vue?macro=true";
import { default as personal_45infoy3JsSFp4iqMeta } from "/opt/buildhome/repo/pages/account/personal-info.vue?macro=true";
import { default as securityXOdRHh5FdmMeta } from "/opt/buildhome/repo/pages/account/security.vue?macro=true";
import { default as confirmHUJ8ZD3q2JMeta } from "/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexJnORAIqgu8Meta } from "/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93RRcroN7dmeMeta } from "/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93FZSdQsAqSYMeta } from "/opt/buildhome/repo/pages/gratuity/[accessKey].vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as _91code_93Nez1lB9l9sMeta } from "/opt/buildhome/repo/pages/referral/[code].vue?macro=true";
import { default as reset_45passwordR3tuU8TAkoMeta } from "/opt/buildhome/repo/pages/reset-password.vue?macro=true";
import { default as set_45password7SadfZikENMeta } from "/opt/buildhome/repo/pages/set-password.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93HxQxjzxhjNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/[...slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/opt/buildhome/repo/pages/404.vue")
  },
  {
    name: "account-bookings-filter",
    path: "/account/bookings/:filter?",
    meta: _91_91filter_93_93R5OzQx1y5ZMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/bookings/[[filter]].vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexy4DdxYIhQyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/index.vue")
  },
  {
    name: "account-notifications",
    path: "/account/notifications",
    meta: notificationsvs4SYXstdeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/notifications.vue")
  },
  {
    name: "account-payments",
    path: "/account/payments",
    meta: paymentscnPfEikkxIMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/payments.vue")
  },
  {
    name: "account-personal-info",
    path: "/account/personal-info",
    meta: personal_45infoy3JsSFp4iqMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/personal-info.vue")
  },
  {
    name: "account-security",
    path: "/account/security",
    meta: securityXOdRHh5FdmMeta || {},
    component: () => import("/opt/buildhome/repo/pages/account/security.vue")
  },
  {
    name: "experiences-parentId-serviceId-confirm",
    path: "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmHUJ8ZD3q2JMeta || {},
    component: () => import("/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/confirm.vue")
  },
  {
    name: "experiences-parentId-serviceId",
    path: "/experiences/:parentId()/:serviceId()",
    meta: indexJnORAIqgu8Meta || {},
    component: () => import("/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/index.vue")
  },
  {
    name: "experiences-parentId-serviceId-return-bookingId",
    path: "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93RRcroN7dmeMeta || {},
    component: () => import("/opt/buildhome/repo/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue")
  },
  {
    name: "gratuity-accessKey",
    path: "/gratuity/:accessKey()",
    meta: _91accessKey_93FZSdQsAqSYMeta || {},
    component: () => import("/opt/buildhome/repo/pages/gratuity/[accessKey].vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login4dfr6kRqBoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/login.vue")
  },
  {
    name: "referral-code",
    path: "/referral/:code()",
    meta: _91code_93Nez1lB9l9sMeta || {},
    component: () => import("/opt/buildhome/repo/pages/referral/[code].vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordR3tuU8TAkoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/reset-password.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45password7SadfZikENMeta || {},
    component: () => import("/opt/buildhome/repo/pages/set-password.vue")
  }
]